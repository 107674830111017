export const FUNNEL_PAGE_GRID_PROPS = {
    leftCol: { xs: 12, l: 8 },
    rightCol: { xs: 4 },
};
export const FUNNEL_PAGE_COLUMN_SPACING = {
    l: 8,
    xl: 10,
};
export const RESPONSIVE_PAPER_PROPS = {
    py: 5,
    px: { xs: 3, s: 4, m: 5 },
};
export const CHECKOUT_FIELD_GRID_PROPS = { xs: 'fill', m: 8, l: 6 };
export const SEARCH_FIELD_POPOVER_INNER_PADDING = 3;
export const SEARCH_FIELD_SPACING = 3;
